import React, { useState, useEffect, useCallback } from "react";
import { Container, Col, Row, Button, Modal } from "react-bootstrap";
import Layout from "../../components/layouts/layout";
import CatalogoLayoutNuevos from "../../components/layouts/CatalogoLayoutNuevos";
import FiltroNuevos from "../../components/lateral/filtro-nuevos";
import SEO from "../../components/seo";
import { isDesktop, isMobile } from "react-device-detect";
import { globalHistory } from "@reach/router";

import { navigate, Link, withPrefix } from "gatsby";
import api from "../../actions/riveroapi";

import ChevRegresar from "../../images/iconosFiltroNew/chevy_regresar.png";
import ChevRegresarA from "../../images/iconosFiltroNew/chevy_regresar_activo.png";
import NissanRegresar from "../../images/iconosFiltroNew/nissan_regresar.png";
import NissanRegresarA from "../../images/iconosFiltroNew/nissan_regresar_act.png";

export default function AutosIndex(props) {

    const [marcaImg, setmarcaImg] = useState([]);
    const [tipo, setTipo] = useState();
    const [filterMarcas, setFilterMarcas] = useState(marcaHome ? [marcaHome] : []);
    const [catalogoMarcasLujo, setCatalogoMarcasLujo] = useState();

    var marcaDeseada = globalHistory.location.pathname.split("/");
    var marcaHome = marcaDeseada[1];
    const parameters = props.location.search;

    let str = props.location.pathname;
    let strsplit = str?.replace(str, " ");
    let splitUrl = str.split("/");
    let strReplace = str?.replace(`/${splitUrl[1]}/catalogo/nuevos`, " ");
    let strCambio = (strReplace === `/${splitUrl[1]}/catalogo/nuevos` ? " " : strReplace);

    const [cars, setCars] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [planes, setPlanes] = useState([]);

    let arrMarcas = [];

    /* const [ filterMarcas, setFilterMarcas ] = useState([]); */
    /* const [ show, setShow ] = useState(false);
    const handleClose = () => setShow(false); */

    useEffect(() => {
        console.log(props, "PROPS");
        onGetCarros();
        //console.log("UseEffect: ", marcaHome)
        /*if (marcaHome && arryMarcas.indexOf(marcaHome) === -1) {
            setArrMarcas([marcaHome]);
            setFilterMarcas([marcaHome]);
        }*/

        /* if (splitUrl[1] === "buick" || splitUrl[1] === "cadillac" || splitUrl[1] === "gmc") {
            onGetCarros();
        } */

        let splitTipo = window.location.pathname.split("/");

        setTipo(splitTipo[4]);
        setmarcaImg(window.location.pathname.split("/"));

    }, []);
    //console.log("Para icono", marcaImg[1])
    const typesImages = [];

    if (splitUrl[1] == "chevrolet") {
        typesImages.push({ image: ChevRegresar, imageChosen: ChevRegresarA })
    } else if (splitUrl[1] == "nissan") {
        typesImages.push({ image: NissanRegresar, imageChosen: NissanRegresarA })
    } else if (splitUrl[1] == undefined) {
        typesImages.push({ image: ChevRegresar, imageChosen: ChevRegresarA })
    }
    console.log("Entra lo siguiente: ", cars)
    /* useEffect(() => {
        onGetCarros()
        onFilter("CHEVROLET", "Marcas")
    }, []); */

    /////// Funciones ///////////

    const onFilter = useCallback((e, proviene) => {
        //console.log("Pulsa: ", e)

        arrMarcas.indexOf(e) === -1 ? arrMarcas.push(e) : arrMarcas.splice(arrMarcas.indexOf(e), 1);
        setFilterMarcas(filterMarcas.concat(arrMarcas));

    }, []);

    function navegante(e) {
        let separar = e.split(" ");

        //console.log("split: ", splitUrl[1]);
        onGetCarros(separar[0].toLowerCase());
        setTipo(separar[0].toLowerCase());
        navigate(`/${splitUrl[1]}/catalogo/nuevos/${separar[0].toLowerCase()}` + parameters, { state: { marcas: filterMarcas } });
        //console.log("Al pulsar tipos: ", filterMarcas);
    }

    //console.log("filter: ", filterMarcas);
    function onGetCarros(e) {

        let autoSplit = strReplace.split("/");
        //console.log("autoSplit: ", autoSplit)

        const options = {
            filterTipos: e || autoSplit[1],
            filterMarcas: splitUrl[1],
            orderBy: "precio:asc",
            ultimoModelo: true,
            perPage: 100,
            mostRecent: true,
        };
        console.log("Params options: ", options);
        setIsLoading(true);
        setTimeout(() => {
            api.getCars(options).then((e) => e.json()).then((ejson) => {

                api.getPlanesNissan().then((e) => {
            
                    /* ejson.data[0]['enganche']='1000'; */
        /*                 console.log("DATA PLANES", ePlanes); */
                    setPlanes(e);

                    for (let $i = 0; $i < ejson.data.length; $i++) {
                        for (let $j = 0; $j < e.length; $j++) {
                            
                            if (ejson.data[$i].slug == e[$j].slug ){
                                ejson.data[$i]['enganche']= e[$j].enganche;
                                ejson.data[$i]['mensualidad']= e[$j].mensualidad;
                            }
                            
                        }
                    }
                    
                })
                    .catch((error) => { console.error(error); });

                const filterImage = ejson.data.filter((obj) => { return obj.image !== null; })
                setCars(filterImage);
                setIsLoading(false);
            })
                .catch((error) => { console.error(error); });
        }, 100)

    }

    /*const enviarMarca = (filterMarcas) => {
        sessionStorage.setItem('ultimaMarcaSeleccionada', filterMarcas);
        console.log(sessionStorage,"que eres tu") 
        navigate(`/` + parameters);
    }*/

    return (
        <Layout selectedMenuItem={"nuevos"} parameters={parameters}>
            <SEO
                title="Nissan Rivero Monterrey - Agencia Autos Nuevos y Seminuevos"
                description={
                    "Grupo Rivero - Agencia Nissan Monterrey March, Versa, " +
                    "Altima, Sentra, Kicks - venta autos nuevos y " +
                    "seminuevos - Nissan Las Torres, Nissan Contry, Nissan Valle"
                }
                keywords="Agencia Nissan Monterrey, carro nuevo, carro nissan"
            />
            <div style={{ minHeight: "76vh" , backgroundColor: "#FFF"}}>
                <Container>
                    {
                        splitUrl[1] === "chevrolet" || splitUrl[1] === "nissan" ? (
                            strCambio === " " ? (
                                <Container className="pt-4">
                                    <Row style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#FFF' }} className="p-4">
                                        <FiltroNuevos onFilter={onFilter} filterMarcas={filterMarcas} navegante={navegante} propps={props} tipo={tipo}/>
                                    </Row>
                                </Container>
                            ) : (
                                <>
                                    <Container className="pt-2 pb-2">
                                        {/* <Row style={{ display: 'flex', alignItems: 'center' }} className="pt-3 pb-3">
                                    <Link
                                        to={
                                            parameters
                                                ? "/" + parameters
                                                : "/"
                                        }
                                        onClick={() => {
                                            if (typeof sessionStorage !== 'undefined') {
                                                sessionStorage.setItem('ultimaMarcaSeleccionada', filterMarcas);
                                            } else {
                                                console.error('sessionStorage is not available');
                                            }
                                        }}
                                        style={{ color: 'white', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                        >
                                        <img
                                            src={typesImages[0].image}
                                            alt="Regresar"
                                            className="mr-2"
                                            loading="lazy"
                                            onMouseOver={(e) => (e.currentTarget.src = typesImages[0].imageChosen)}
                                            onMouseOut={(e) => (e.currentTarget.src = typesImages[0].image)}
                                        />
                                        <strong>Regresar</strong>
                                    </Link>
                                </Row> */}
                                        <Row style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#FFF', marginTop: '-10px', flexWrap:'nowrap' }} className="p-2 pt-1" >
                                            <Col md={2} sm={2} className="p-0 regresar-responsive">
                                                <Link
                                                    to={
                                                        parameters
                                                            ? "/" + parameters
                                                            : "/"
                                                    }
                                                    onClick={() => {
                                                        if (typeof sessionStorage !== 'undefined') {
                                                            sessionStorage.setItem('ultimaMarcaSeleccionada', filterMarcas);
                                                        } else {
                                                            console.error('sessionStorage is not available');
                                                        }
                                                    }}/* 
                                                    style={{ color: 'white', cursor: 'pointer', display: 'flex', alignItems: 'center' }} */
                                                >
                                                    <img
                                                        src={typesImages[0].image}
                                                        alt="Regresar"
                                                        className="mr-2 styleImgRegresar"
                                                        loading="lazy"
                                                        style={{maxWidth:'40px'}}
                                                        onMouseOver={(e) => (e.currentTarget.src = typesImages[0].imageChosen)}
                                                        onMouseOut={(e) => (e.currentTarget.src = typesImages[0].image)}
                                                    />
                                                    {/* <strong>Regresar</strong> */}
                                                </Link>
                                            </Col>
                                            <Col className="p-0" md={11} sm={12}>
                                                <FiltroNuevos onFilter={onFilter} filterMarcas={filterMarcas} navegante={navegante} parameters={parameters} propps={props} tipo={tipo}/>
                                                {/* <Button style={{ borderRadius: '10px', width: '30%' }} onClick={() => { setShow(true) }}>
                                                    Filtro
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-funnel-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
                                                        <path fillRule="evenodd" d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z" />
                                                    </svg>
                                                </Button> */}
                                            </Col>
                                        </Row>
                                    </Container>
                                    {/* <Container className="pt-2 pb-4">
                                        <Row  className="p-3" style={{backgroundColor: '#343a40'}}>
                                            <Col md={3} sm={3} style={{display: 'flex', alignItems: 'center'}}>
                                                <Link to={withPrefix(`/catalogo/nuevos`)} style={{color: 'white', cursor: 'pointer'}}><strong >&lt; Regresar</strong></Link>
                                            </Col>
                                            <Col md={6} sm={6} style={{display: 'flex', justifyContent: 'center'}}>
                                                <Button style={{borderRadius: '10px', width: '30%'}} onClick={() => {setShow(true)}}>
                                                    Filtro
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-funnel-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
                                                    <path fillRule="evenodd" d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z" />
                                                </svg>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container> */}
                                    <div >
                                        <CatalogoLayoutNuevos post={cars} isLoading={isLoading} props={props} parameters={parameters} />
                                    </div>
                                </>
                            )
                        ) : (
                            <Container>
                                <Row style={{ display: 'flex', alignItems: 'center' }} className="pt-3 pb-3">
                                    {/* <Link to={withPrefix(`/catalogo/nuevos`)} style={{ color: 'white', cursor: 'pointer' }}><strong >&lt; Regresar</strong></Link> */}
                                    {/*<Button onClick={() => enviarMarca(filterMarcas)} style={{ color: 'white', cursor: 'pointer', width: 130 }}><strong >&lt; Regresar</strong></Button>*/}
                                    <Link
                                        to={
                                            parameters
                                                ? "/" + parameters
                                                : "/"
                                        }
                                        onClick={() => {
                                            if (typeof sessionStorage !== 'undefined') {
                                                sessionStorage.setItem('ultimaMarcaSeleccionada', filterMarcas);
                                            } else {
                                                console.error('sessionStorage is not available');
                                            }
                                        }}
                                        style={{ color: 'white', cursor: 'pointer' }}
                                    >
                                        <strong >&lt; Regresar</strong>
                                    </Link>

                                </Row>
                                <CatalogoLayoutNuevos post={cars} isLoading={isLoading} props={props} parameters={parameters} />
                            </Container>
                        )
                    }

                </Container>
                <Container className="pt-5 pb-5"></Container>
                {/*<Container fluid>
                    <Row className="pt-4 pb-4">
                            <Col>
                                <h1 style={{ fontSize: 15, color: "gray" }}>
                                    Agencia Chevrolet Monterrey
                                </h1>
                                <h2 style={{ fontSize: 12, color: "gray" }}>
                                    Encuentra tu auto nuevo con Grupo Rivero Agencia Chevrolet!
                                </h2>
                                <h5 style={{ fontSize: 10, color: "gray" }}>Buscar tú auto nuevo</h5>
                            </Col>
                    </Row>
                </Container>*/}
                {/* 
                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FiltroNuevos onFilter={onFilter} filterMarcas={filterMarcas} navegante={navegante} colorModal={true}/>
                    </Modal.Body>
                </Modal> */}
            </div>
        </Layout>
    )
}